<template>
  <div>
    <div class="qxjzcy">
      <div class="jzimg">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/qxjzcy2.png"
          alt=""
        />
      </div>
      <div class="content">
        <div class="ctx1">
          欢迎来到情绪能量测试，你需要完成一系列与情绪价值有关的题目，大概需要10-20分钟的时间。
        </div>
        <div class="ctx2">
          这些问题的选项并没有“正确”或“错误之分，你只需要根据题目的说明，选择最适合你的选项就可以。
        </div>
        <div class="ctx2">
          在4阶的练习完成后，你可以再进行一次测试，看到自己的变化和收获。
        </div>
        <div class="ctxbtn">
          <button @click="toworthtest">开始测试</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toworthtest() {
      this.$router.push("/test");
    },
  },
  mounted() {
    if (this.$route.query.item) {
      this.id = this.$route.query.item.id;
      this.item = this.$route.query.item;
      console.log(this.id);
    }
  },
};
</script>
<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.qxjzcy {
  background-color: #f4fafe;
  // min-height: 100vh;
  padding: 0px 20px 0px 20px;

  .jzimg {
    display: flex;
    justify-content: center;
    img {
      width: 181px;
      height: 116px;
    }
  }
  .content {
    box-sizing: border-box;
    height: 452px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 2px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    position: relative;
    bottom: 16px;
    padding: 40px 20px;
    .ctx1 {
      text-indent: 20px;
      line-height: 30px;
    }
    .ctx2 {
      text-indent: 20px;
      line-height: 30px;
    }
    .ctxbtn {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      button {
        width: 162px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        border-radius: 50px;
        background-color: #72acf4;
        border: 0;
      }
    }
  }
}
</style>
